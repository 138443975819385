import React, { useContext } from "react";
import {
  BASIC,
  GOOGLE,
  INDIVIDUAL,
  LTD_PLANS_LIMIT,
  PRO,
  PRO_PLUS,
  PROFESSIONAL,
  TEAM,
} from "../../../../../common/constants";
import { capitalizeWord } from "../../../../../common/utils";
import { AppContext } from "../../../../../AppContext";

const SetSubscriptionFeatures = ({ features, planName, isLTDPlan }) => {
  const subsFeature = [
    features?.f_contacts,
    "",
    "",
    features?.f_sync_sources,
    features?.f_groups_tags,
    features?.f_notes === "private" ? "Public & Private" : "Public",
    "",
    "",
    features?.f_permission_management,
    "",
    features?.f_mobile_apps,
    "",
    "",
    "",
  ];
  const googleSubsFeature = [
    "",
    "",
    features?.f_space_sharing,
    features?.f_groups_tags,
    features?.f_notes === "private" ? "Public & Private" : "Public",
    features?.f_documents,
    features?.f_contact_activities,
    features?.f_permission_management,
    features?.f_reminders,
    "",
    features?.f_mobile_apps,
    "",
    "",
    "",
  ];

  const LTDPlanFeatures = [
    planName === "BUSINESS [LTD]" || planName === "License Tier 4"
      ? "Unlimited Contacts"
      : `${features?.f_contacts} Contacts`,
    LTD_PLANS_LIMIT?.groupSharing[planName] ?? "No",
    "Cross account sharing",
    LTD_PLANS_LIMIT?.spaceSharing[planName] ?? "No",
    `Import ${features?.f_sync_sources} Contact (Google & Microsoft) Account`,
    `${features?.f_groups_tags} Groups & Tags`,
    features?.f_permission_management === "Yes"
      ? "Manage permissions"
      : "No permissions management",
    "Real-time Updates",
    features?.f_mobile_app === "Yes"
      ? "Android / iOS Applications"
      : "No Android / iOS Applications",
    features?.f_notes === "public" ? "Public Notes" : "Public & Private Notes",
    LTD_PLANS_LIMIT?.support[planName] ?? "No Support",
    planName === "BASIC [LTD]" || planName === "License Tier 1"
      ? "No storage for attachments"
      : `Up to ${features?.f_documents} GB storage for attachments`,
    // eslint-disable-next-line no-nested-ternary
    features?.f_reminders === "Yes"
      ? "Unlimited Reminders"
      : planName === "BASIC [LTD]" || planName === "License Tier 1"
      ? "No reminders"
      : `Up to ${features?.f_reminders} Reminders`,
    LTD_PLANS_LIMIT?.activity[planName] ?? "No Activity History",
    LTD_PLANS_LIMIT?.installations[planName] ?? "No Installation",
    LTD_PLANS_LIMIT?.contactBackup[planName] ?? "No Contact Backup",
  ];

  const {
    state: { userSubscriptionInfo },
  } = useContext(AppContext);

  const isGoogleWorkSpace = userSubscriptionInfo?.type === GOOGLE;
  const planFeatures = isGoogleWorkSpace ? googleSubsFeature : subsFeature;

  return isLTDPlan
    ? LTDPlanFeatures?.map((feature, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className="cms-feature-list cms-pro-feature-list">
          <span className="font-inter cms-feature-list-title">{feature}</span>
        </div>
      ))
    : planFeatures?.map((feature, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className="cms-feature-list cms-pro-feature-list">
          <span className="font-inter cms-feature-list-title">
            {i === 0 &&
              isGoogleWorkSpace &&
              ((planName === PROFESSIONAL && 1500) ||
                (planName === TEAM && 25000))}
            {i === 1 &&
              isGoogleWorkSpace &&
              ((planName === PROFESSIONAL && 5) ||
                (planName === TEAM && "Unlimited"))}
            {i === 3 && (planName === PRO || planName === PRO_PLUS) && "Up to "}
            {i === 6 && planName === BASIC ? (
              "-"
            ) : (
              <>
                {capitalizeWord(feature) || (i === 10 && "Yes")}
                {isGoogleWorkSpace && i === 5 && "GB"}
              </>
            )}
          </span>
          {i === 1 && (
            <>
              {" "}
              <span className="font-inter cms-feature-list-title">
                {(planName === INDIVIDUAL && "-") ||
                  (planName === BASIC && "Up to 50") ||
                  (planName === PRO_PLUS && "Unlimited") ||
                  (planName === PRO && "Up to 100 ")}
              </span>
            </>
          )}

          {i === 2 && !isGoogleWorkSpace && (
            <>
              <span className="font-inter cms-feature-list-title">
                {(planName === INDIVIDUAL && "-") ||
                  (planName === BASIC && "Up to 50 users") ||
                  (planName === PRO && "Up to 100 users") ||
                  (planName === PRO_PLUS && "Unlimited users")}
              </span>
            </>
          )}
          {!isGoogleWorkSpace && i === 6 && planName !== BASIC && (
            <span className="font-inter cms-feature-list-title">
              {planName === INDIVIDUAL && "Up to 10 GB"}{" "}
              {planName === PRO && "Up to 10 GB"}{" "}
              {planName === PRO_PLUS && "Up to 100 GB"}
              <span className="cms-subs-plan-text cms-text-mLeft">
                per space
              </span>
            </span>
          )}

          {!isGoogleWorkSpace && i === 9 && (
            <span className="font-inter cms-feature-list-title">
              {(planName === INDIVIDUAL && "Up to 5") ||
                (planName === BASIC && "No") ||
                (planName === PRO && "Up to 5") ||
                (planName === PRO_PLUS && "Unlimited")}
            </span>
          )}

          {isGoogleWorkSpace && i === 9 && (
            <span className="font-inter cms-feature-list-title">
              {(planName === PROFESSIONAL && "Once a day") ||
                (planName === TEAM && "Every 4 hours")}
            </span>
          )}

          {!isGoogleWorkSpace && i === 7 && (
            <span className="font-inter cms-feature-list-title">
              {planName === INDIVIDUAL && "6 months"}
              {planName === PRO_PLUS && "Lifetime"}
              {planName === PRO && "6 months"}
              {planName === BASIC && "No"}
            </span>
          )}

          {i === 11 && (
            <span className="font-inter cms-feature-list-title">
              {planName === INDIVIDUAL && "Email"}
              {planName === BASIC && "Email"}
              {planName === PRO && "Priority"}
              {planName === PRO_PLUS && "Dedicated"}
              {planName === PROFESSIONAL && "Email"}
              {planName === TEAM && "Priority"}
            </span>
          )}
          {i === 12 && (
            <span className="font-inter cms-feature-list-title">
              {planName === INDIVIDUAL && "No"}
              {planName === BASIC && "No"}
              {planName === PRO && "No"}
              {planName === PRO_PLUS && "Yes"}
              {planName === PROFESSIONAL && "No"}
              {planName === TEAM && "Yes"}
            </span>
          )}
          {i === 13 && (
            <span className="font-inter cms-feature-list-title">
              {planName === INDIVIDUAL && "No"}
              {planName === BASIC && "No"}
              {planName === PRO && "No"}
              {planName === PRO_PLUS && "Yes"}
              {planName === PROFESSIONAL && "No"}
              {planName === TEAM && "Yes"}
            </span>
          )}
        </div>
      ));
};

export default SetSubscriptionFeatures;
