import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import api from "../../../common/api";
import Layout from "../layout/Layout";
import { ROUTES, WINDOW_SIZE } from "../../../common/constants";
import { IS_EMAIL_EXISTS } from "../graphql/Mutations";
import { AppContext } from "../../../AppContext";
import { formValidatorRules } from "../../../common/utils";
import Loading from "../../../common/components/Loading";
import IconButton from "../../../common/components/IconButton/IconButton";
import { EmailLetter } from "../../../assets/svg";
import TermsComponent from "../../../common/TermsComponent";
import { SET_CURRENT_EMAIL } from "../../../common/actionTypes";

function LoginAppSumo() {
  const [form] = useForm();
  const history = useHistory();
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext);
  const { width } = useWindowSize();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const appSumoKey = params.get("code");
  const [appKey, setAppKey] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const { required, email } = formValidatorRules;
  const [emailExists, { loading: emailExistsLoader }] = useMutation(
    IS_EMAIL_EXISTS,
    {
      onCompleted(data) {
        if (data?.isEmailExist?.isLoggedIn) {
          dispatch({ type: SET_CURRENT_EMAIL, data: currentEmail });
          history?.push(ROUTES?.EMAIL_LOGIN);
        } else {
          dispatch({ type: SET_CURRENT_EMAIL, data: currentEmail });
          history?.push(
            appKey ? `${ROUTES?.SIGNUP}?key=${appKey}` : ROUTES?.SIGNUP
          );
        }
      },
    }
  );

  useEffect(() => {
    if (appSumoKey) {
      api
        ?.get(`/appsumo-auth?code=${appSumoKey}`)
        .then(function (res) {
          setAppKey(res?.data?.licenseKey);
          setLoading(false);
        })
        .catch(function (error) {
          const errorMsg =
            error?.response?.data?.message ||
            "Something went wrong. Please try again.";
          setErrorMessage(errorMsg);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSumoKey]);

  useEffect(() => {
    if (currentUser?.id) {
      if (width <= WINDOW_SIZE) {
        history?.push(ROUTES?.CONTACTS);
      } else {
        // eslint-disable-next-line no-unused-expressions, no-nested-ternary
        localStorage?.getItem("TOKEN")
          ? history?.push(`/contacts/${currentUser?.spaceId}/group/all`)
          : history?.push("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleFinish = (value) => {
    const emailAddress = value?.email;
    setCurrentEmail(emailAddress);
    emailExists({
      variables: { email: emailAddress },
    })?.catch((error) => {
      return error;
    });
  };

  return loading ? (
    <div className="cms-center-loading">
      <Loading />
    </div>
  ) : (
    <Layout>
      <div className="login-container">
        <div className="login-container-content">
          {errorMessage ? (
            <div className="login-container-content-top-section">
              <span className="font-inter google-text mt-20">
                {errorMessage}
              </span>
            </div>
          ) : (
            <>
              <div className="login-container-content-top-section">
                <span className="font-inter google-text mt-20">
                  Welcome to ContactBook
                </span>
              </div>
              <div className="login-container-content-bottom-section">
                <span className="flex-all-center full-width">
                  <Form
                    form={form}
                    layout="vertical"
                    className="full-width"
                    onFinish={handleFinish}
                    requiredMark={false}
                  >
                    <Form.Item
                      name="email"
                      rules={[required, email]}
                      validateTrigger={["onPressEnter"]}
                    >
                      <Input
                        placeholder="Enter email address"
                        className="cms-cont-input"
                      />
                    </Form.Item>
                    <Form.Item name="email">
                      <IconButton
                        htmlType="submit"
                        iconClass="cms-cont-email mt-16 mb-16"
                        text={
                          <span className="font-inter cms-cont-email-text ml-8">
                            Continue with Email
                          </span>
                        }
                        loading={emailExistsLoader}
                        icon={<EmailLetter />}
                      />
                    </Form.Item>
                  </Form>
                </span>
              </div>
              <div className="mt-24 cms-text-center">
                <TermsComponent />
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default LoginAppSumo;
