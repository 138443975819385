import React, { useRef, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";

//! Ant import
import Switch from "antd/lib/switch";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Form from "antd/lib/form";

//! User files
import {
  GOOGLE,
  OWNER,
  PRIVATE,
  VISIBILITY,
} from "../../../../../common/constants";
import UpgradeButtonComponent from "../../../../../common/UpgradeButtonComponent";
import { AppContext } from "../../../../../AppContext";

const { TextArea } = Input;
const AddNotePopup = ({ addNotes, setVisibility, form, notesLoader }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const notesRef = useRef();
  const {
    state: { userSubscriptionInfo, userGroupPermission, userSpacePermission },
  } = useContext(AppContext);
  const isNotePrivateAllowed =
    userSubscriptionInfo?.subscription?.product?.features?.f_notes === PRIVATE;
  const { spaceId } = useParams();

  const addNoteButton = () => {
    notesRef.current.click();
  };

  const handleNotesVisibility = (val) => {
    if (val) {
      setVisibility(VISIBILITY.PRIVATE);
    }
  };

  const checkFormValue = (value) => {
    if (value?.note?.trim()?.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  return (
    <div className="add-note-modal">
      <div className="add-note-modal-title">
        <span>Add Comment</span>
      </div>
      <div className="add-note-modal-body">
        <Form
          form={form}
          name="add-note-form"
          onFinish={addNotes}
          onValuesChange={checkFormValue}
          className="add-note-form"
        >
          <Form.Item name="note">
            <TextArea
              autoSize={{ minRows: 9, maxRows: 9 }}
              className="cms-contact-scrollbar"
            />
          </Form.Item>
          <Button className="hide" ref={notesRef} htmlType="submit">
            Submit
          </Button>
        </Form>
      </div>
      <div className="add-note-modal-footer">
        <span>
          <Switch
            onChange={(e) => handleNotesVisibility(e)}
            disabled={
              !isNotePrivateAllowed && userSubscriptionInfo?.type !== GOOGLE
            }
          />
        </span>
        <span
          className={`${
            !isNotePrivateAllowed && userSubscriptionInfo?.type !== GOOGLE
              ? "add-note-disable"
              : ""
          } add-note-private`}
        >
          Private Comment
        </span>
        <span>
          <Button
            className={`${
              isDisabled ? "add-note-button-disable" : ""
            } add-note-button`}
            type="primary"
            htmlType="submit"
            onClick={addNoteButton}
            disabled={isDisabled || notesLoader}
            loading={notesLoader}
          >
            Add Comment
          </Button>
        </span>
      </div>
      {!isNotePrivateAllowed && userSubscriptionInfo?.type !== GOOGLE && (
        <div className="add-note-modal-upgrade font-inter">
          <div>
            {userGroupPermission === OWNER || userSpacePermission === OWNER ? (
              <>
                You must upgrade to Pro or Pro+ plan to use Private Notes
                feature.
              </>
            ) : (
              <>
                This space is shared with you and Private Notes feature is not
                available in your space. Please contact Space Owner to switch to
                a plan with this feature.
              </>
            )}
          </div>
          {(userGroupPermission === OWNER || userSpacePermission === OWNER) && (
            <Link to={`/space/${spaceId}/plans`}>
              <UpgradeButtonComponent />
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default AddNotePopup;
