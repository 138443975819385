import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import ReactGA from "react-ga";
import { Skeleton } from "antd";

//! User files
import {
  SET_SPACE_PLAN_STATUS,
  SET_USER_SPACE_PERMISSION,
  SET_USER_SUBSCRIPTION_INFO,
} from "../../../../../common/actionTypes";
import { AppContext } from "../../../../../AppContext";
import PlanPageSkeleton from "../../../../../common/components/PlanPageSkeleton/PlanPageSkeleton";
import { createAmplitudeEvent } from "../../../../../common/utils";
import {
  AMPLITUDE_EVENT_LOG,
  GOOGLE,
  INR,
  LTD,
  MONTH,
  OWNER,
  PLAN_NAMES,
  PLAN_STATUS,
  ROUTES,
  SUCCESS,
  USD,
  YEAR,
} from "../../../../../common/constants";
import SubscriptionHeader from "./SubscriptionHeader";
import SubscriptionFeatures from "./SubscriptionFeatures";
import SubscriptionPlans from "./SubscriptionPlans";
import NoAccessComponent from "../../../../../common/components/NoAccessComponent/NoAccessComponent";
import Loading from "../../../../../common/components/Loading";

//! Graphql files
import { RETRY_PAYMENT } from "../../../graphql/queries/retryPayment";
import { NEW_SPACE_SUBSCRIPTION } from "../../../graphql/mutations/newSpaceSubscription";
import { GET_PRODUCT } from "../../../graphql/queries/getProducts";
import { UPDATE_SUBSCRIPTION } from "../../../graphql/mutations/updateSubscription";
import { RETRIEVE_SUBSCRIPTION_CHANGES } from "../../../graphql/mutations/retrieveSubscriptionChanges";
import { USER_PAYMENT_METHOD } from "../../../graphql/queries/userPaymentMethod";
import { MANAGE_BILLING } from "../../../graphql/queries/manageBilling";
import { GET_GENERAL_SPACE_INFO } from "../../../graphql/queries/getGeneralSpaceInfo";

const Subscription = () => {
  const {
    state: { userSubscriptionInfo },
    dispatch,
  } = useContext(AppContext);
  const [sliderValue, setSliderValue] = useState(
    userSubscriptionInfo?.type === GOOGLE ? 5 : 10
  );
  const [currency, setCurrency] = useState(null);
  const [products, setProducts] = useState([]);
  const [spaceDetail, setSpaceDetail] = useState([]);
  const [subscriptionChanges, setSubscriptionChanges] = useState([]);
  const [open, setOpen] = useState(false);
  const [planPriceId, setPlanPriceId] = useState(null);
  const [interval, setInterval] = useState(YEAR);
  const { spaceId } = useParams();
  const [cardDetailsAdded, isCardDetailsAdded] = useState();
  const [creditPopup, openCreditPopup] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [updatedUserCount, setUpdatedUserCount] = useState(
    spaceDetail?.subscription?.quantity
  );
  const [creditText, setCreditText] = useState(false);
  const [isLTDPlan, setIsLTDPlan] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (userSubscriptionInfo?.subscription?.product?.price?.interval === LTD) {
      setIsLTDPlan(true);
    }
    if (userSubscriptionInfo?.subscription?.quantity) {
      const subsQty = userSubscriptionInfo?.subscription?.quantity;
      let sliderQty = 0;
      if (userSubscriptionInfo?.type === GOOGLE) {
        sliderQty = subsQty > 5 ? subsQty : 5;
      } else {
        sliderQty = subsQty > 10 ? subsQty : 10;
      }
      setSliderValue(sliderQty);
    } else if (userSubscriptionInfo?.type === GOOGLE) {
      setSliderValue(5);
    } else {
      setSliderValue(10);
    }
  }, [userSubscriptionInfo]);

  const [getPlans, { loading: planLoader }] = useLazyQuery(GET_PRODUCT, {
    fetchPolicy: "cache-and-network",
    variables: {
      interval: isLTDPlan ? LTD : interval,
      spaceId: parseInt(spaceId, 10),
    },
    // eslint-disable-next-line no-shadow
    onCompleted({ products }) {
      if (products) {
        if (userSubscriptionInfo?.type === GOOGLE) {
          setProducts(products?.data);
        } else {
          const productData = cloneDeep(products?.data);
          const temp = productData[0];
          // eslint-disable-next-line prefer-destructuring
          productData[0] = productData[2];
          productData[2] = temp;
          setProducts(productData);
        }
      }
    },
  });

  const [newSpaceSubscription, { loading: newSpaceLoader }] = useMutation(
    NEW_SPACE_SUBSCRIPTION,
    {
      // eslint-disable-next-line no-shadow
      onCompleted({ newSpaceSubscription }) {
        window.location.href = newSpaceSubscription?.url;
      },
      onError() {},
    }
  );

  const [getSpaceDetail, { loading: spaceLoader }] = useLazyQuery(
    GET_GENERAL_SPACE_INFO,
    {
      fetchPolicy: "no-cache",
      onCompleted({ spaceInfo }) {
        setInterval(
          // eslint-disable-next-line no-nested-ternary
          spaceInfo?.data?.subscription?.planStatus === PLAN_STATUS.ACTIVE
            ? spaceInfo?.data?.subscription?.product?.price?.interval ===
              "month"
              ? MONTH
              : YEAR
            : YEAR
        );
        setSpaceDetail(spaceInfo?.data);
        setSliderValue(spaceInfo?.data?.subscription?.quantity);
        dispatch({
          type: SET_SPACE_PLAN_STATUS,
          data: { planExpired: false },
        });
        dispatch({
          type: SET_USER_SUBSCRIPTION_INFO,
          data: spaceInfo?.data,
        });
        dispatch({
          type: SET_USER_SPACE_PERMISSION,
          data: spaceInfo?.data?.permission,
        });
      },
      onError() {},
    }
  );

  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION, {
    // eslint-disable-next-line no-shadow
    onCompleted({ updateSubscription }) {
      if (updateSubscription?.url) {
        window.open(updateSubscription.url, "_blank");
        window.location.reload();
      }
      history?.push(ROUTES?.SUBSCRIPTION_UPDATED);
      if (updateSubscription?.status === SUCCESS) {
        setTimeout(() => {
          getSpaceDetail({
            variables: {
              id: parseInt(spaceId, 10),
            },
          });
          setUpdateLoader(false);
          setOpen(false);
          openCreditPopup(false);
        }, 3000);
      }
    },
    onError() {},
  });

  const [
    retrieveSubscriptionChanges,
    { loading: retrieveLoader },
  ] = useMutation(RETRIEVE_SUBSCRIPTION_CHANGES, {
    // eslint-disable-next-line no-shadow
    onCompleted({ retrieveSubscriptionChanges }) {
      setSubscriptionChanges(retrieveSubscriptionChanges);
      if (retrieveSubscriptionChanges?.immediateTotal / 100 < 0) {
        setCreditText(true);
      } else {
        setCreditText(false);
      }
    },
    onError() {},
  });

  useQuery(USER_PAYMENT_METHOD, {
    onCompleted({ userPaymentMethod }) {
      isCardDetailsAdded(userPaymentMethod?.available);
      isCardDetailsAdded(false);
    },
    onError() {},
  });
  const [manageBilling, { loading: billingLoader }] = useLazyQuery(
    MANAGE_BILLING,
    {
      variables: {
        spaceId: parseInt(spaceId, 10),
      },
      // eslint-disable-next-line no-shadow
      onCompleted({ manageBilling }) {
        window.open(manageBilling?.url, "_blank");
        window.location.reload();
      },
    }
  );
  const [retryPayment, { loading: paymentLoader }] = useLazyQuery(
    RETRY_PAYMENT,
    {
      variables: {
        id: parseInt(spaceId, 10),
      },
      // eslint-disable-next-line no-shadow
      onCompleted({ retryPayment }) {
        window.open(retryPayment?.url, "_blank");
        window.location.reload();
      },
    }
  );

  useEffect(() => {
    if (spaceId > 0) {
      getSpaceDetail({
        variables: {
          id: parseInt(spaceId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  useEffect(() => {
    if (products) {
      setCurrency(products?.[0]?.price?.currency === INR ? INR : USD);
    }
  }, [products]);

  useEffect(() => {
    if (interval) {
      getPlans();
      localStorage.removeItem("redirectToPricing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, interval]);

  const handleSpaceSubscription = (priceId, selectedPlan) => {
    if (priceId) {
      setPlanPriceId(priceId);
      newSpaceSubscription({
        variables: {
          priceId,
          spaceId: parseInt(spaceId, 10),
          quantity:
            selectedPlan?.planName === PLAN_NAMES?.INDIVIDUAL
              ? 1
              : parseInt(updatedUserCount, 10),
        },
      }).then(() => {
        localStorage.setItem("currentSpace", spaceId);
        const eventProperties = {
          "Space Id": spaceId,
          "Plan Name": selectedPlan?.planName,
          "Plan Amount": selectedPlan?.amount,
          "Plan currency": selectedPlan?.currency,
          "Plan quantity": selectedPlan?.quantity,
          "Plan interval": selectedPlan?.interval,
        };
        createAmplitudeEvent(
          AMPLITUDE_EVENT_LOG.NEW_SUBSCRIPTION,
          eventProperties
        );
        ReactGA.event({
          category: "New subscription",
          action: "cb-new-subscription",
          label: "New subscription purchased",
        });
      });
    }
  };

  const handleUpdateSubscription = (priceId, subscription, selectedPlan) => {
    if (priceId) {
      setUpdateLoader(true);
      updateSubscription({
        variables: {
          priceId,
          spaceId: parseInt(spaceId, 10),
          quantity:
            selectedPlan?.planName === PLAN_NAMES?.INDIVIDUAL
              ? 1
              : parseInt(updatedUserCount, 10),
        },
      }).then(() => {
        localStorage.setItem("currentSpace", spaceId);
        const eventProperties = {
          "Space Id": spaceId,
          "Plan Name": subscription?.name,
          "Plan Amount": subscription?.price?.amount,
          "Plan currency": subscription?.price?.currency,
          "Plan quantity": subscription?.quantity,
          "Plan interval": subscription?.price?.interval,
        };
        history?.push(ROUTES?.UPDATE_SUBSCRIPTION);
        createAmplitudeEvent(
          AMPLITUDE_EVENT_LOG.UPDATE_SUBSCRIPTION,
          eventProperties
        );
      });
    }
  };

  const retrieveSubscription = (priceId, planName) => {
    if (priceId) {
      retrieveSubscriptionChanges({
        variables: {
          spaceId: parseInt(spaceId, 10),
          priceId,
          quantity:
            planName === PLAN_NAMES?.INDIVIDUAL
              ? 1
              : parseInt(updatedUserCount, 10) || spaceDetail?.membersCount,
        },
      });
    }
  };

  // eslint-disable-next-line no-nested-ternary
  return spaceLoader ? (
    <div className="cms-pageLoader">
      <Loading />
    </div>
  ) : spaceDetail?.permission === OWNER ? (
    <div className="cms-subscription-wrapper cms-contact-scrollbar">
      <>
        <SubscriptionHeader
          setInterval={setInterval}
          interval={interval}
          setSliderValue={setSliderValue}
          sliderValue={sliderValue}
          spaceDetail={spaceDetail}
          cardDetailsAdded={cardDetailsAdded}
          manageBilling={manageBilling}
          billingLoader={billingLoader}
          retryPayment={retryPayment}
          paymentLoader={paymentLoader}
          isLTDPlan={isLTDPlan}
        />

        {
          // eslint-disable-next-line no-nested-ternary
          planLoader ? (
            isLTDPlan ? (
              <Skeleton
                className="cms-subscription-detail plan-skeleton-body full-height skeleton-center"
                paragraph={{ rows: 10 }}
                title={false}
                active
              />
            ) : (
              <PlanPageSkeleton
                className={`cms-subscription-detail ${
                  isLTDPlan && "full-height"
                }`}
              />
            )
          ) : (
            products?.length > 0 && (
              <div
                className={`cms-subscription-detail ${
                  isLTDPlan && "full-height"
                }`}
              >
                {!isLTDPlan && (
                  <SubscriptionFeatures
                    isGoogleWorkSpace={userSubscriptionInfo?.type === GOOGLE}
                  />
                )}
                <SubscriptionPlans
                  selectedInterval={interval}
                  products={products}
                  handleSpaceSubscription={handleSpaceSubscription}
                  spaceDetail={spaceDetail}
                  handleUpdateSubscription={handleUpdateSubscription}
                  retrieveSubscription={retrieveSubscription}
                  subscriptionChanges={subscriptionChanges}
                  retrieveLoader={retrieveLoader}
                  updateLoader={updateLoader}
                  setOpen={setOpen}
                  open={open}
                  sliderValue={sliderValue}
                  newSpaceLoader={newSpaceLoader}
                  planPriceId={planPriceId}
                  cardDetailsAdded={cardDetailsAdded}
                  openCreditPopup={openCreditPopup}
                  creditPopup={creditPopup}
                  updatedUserCount={updatedUserCount}
                  setUpdatedUserCount={setUpdatedUserCount}
                  creditText={creditText}
                  setCreditText={setCreditText}
                  isLTDPlan={isLTDPlan}
                />
              </div>
            )
          )
        }
      </>
    </div>
  ) : (
    <NoAccessComponent />
  );
};

export default Subscription;
